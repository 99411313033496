<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="w-aim-wrap"
  >
    <div class="w-aim-management-main">
      <div class="w-aim-head">
        <div class="w-crumbs-href">
          <router-link
            tag="span"
            :to="{ path: '/' }"
          >工作台</router-link>
          <i class="el-icon-arrow-right"></i>
          <router-link
            tag="span"
            :to="{ path: '/work' }"
          >招商规划管理</router-link>
          <i class="el-icon-arrow-right"></i>
          <span class="w-white">招商目标管理</span>
        </div>
        <!-- <div class="w-berth-btn">
          <span class="w-drop-btn">批量编辑</span>
          <span class="w-export-btn" @click="exportInvestment()">
            <i class="icon-export"></i> 导出
          </span>
        </div> -->
      </div>
      <div
        class="w-aim-screen"
        v-if="hasPermission"
      >
        <div class="w-select-project">
          <el-select
            popper-class="w-block-select-down"
            v-model="projectId"
            placeholder="请选择"
            @change="changeProject"
          >
            <el-option
              v-for="item in projectList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="w-screen-list">
          <div class="w-input-item w-input-sty">
            <span class="w-type-name">铺位号：</span>
            <input
              class="el-input__inner"
              type="text"
              v-model="berthNo"
              placeholder="请输入铺位号"
            />
          </div>
          <div class="w-input-item w-input-sty">
            <span class="w-type-name">楼宇楼层：</span>
            <el-cascader
              popper-class="black-select-panel"
              v-model="selectFloor"
              :options="filterFloorArr"
              change-on-select
              @change="changeFloor"
              ref="myCascader"
              filterable
              clearable
            ></el-cascader>
          </div>
          <div class="w-input-item w-input-sty">
            <span class="w-type-name">规划业态：</span>
            <el-select
              popper-class="w-block-select-down"
              v-model="formatStr"
              @change="changeFormat"
              placeholder="请选择"
            >
              <el-option
                v-for="item in formatList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="w-input-item w-input-sty">
            <span class="w-type-name">审批状态：</span>
            <el-select
              popper-class="w-block-select-down"
              v-model="approvalStatus"
              @change="changeApproval"
              placeholder="全部"
            >
              <el-option
                v-for="item in approvalStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="w-query-btn"
            @click="searchBtn()"
          >
            <span>查询</span>
          </div>
        </div>
      </div>
      <div
        class="w-singed-table"
        v-if="hasPermission"
      >
        <table>
          <thead>
            <tr>
              <td width="120">铺位ID</td>
              <td width="60">楼宇</td>
              <td width="70">楼层</td>
              <td width="80">铺位号</td>
              <td width="90">铺位类型</td>
              <td width="100">是否主力店</td>
              <td width="120">铺位规划业态</td>
              <td width="130">时间段</td>
              <td width="100">租金类型</td>
              <td width="160">标准租金(元/月/㎡)</td>
              <td width="140">保底租金(元/月/㎡)</td>
              <td width="90">审批状态</td>
              <td width="120">操作</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in brandList.dataList"
              :key="index"
              @dblclick="toAimDetail(item, '1')"
            >
              <td>{{item.berthId}}</td>
              <td>{{item.building}}</td>
              <td>{{item.floor}}</td>
              <td>{{item.berthNo}}</td>
              <td v-if="item.berthType === '1'">铺位</td>
              <td v-else-if="item.berthType === '2'">广告位</td>
              <td v-else-if="item.berthType === '3'">场地</td>
              <td v-else>写字楼</td>
              <td>{{item.mainStore === 0 ? '普通店' : item.mainStore === 1 ? '主力店' : '次主力店'}}</td>
              <td>{{item.industry1}}</td>
              <td>{{item.startTime?item.startTime+'至'+item.endTime:'-'}}</td>
              <td>{{item.priceType?(item.priceType===1?'固定租金':'提成租金'): '-'}}</td>
              <td>{{item.rentPrice?item.rentPrice:'-'}}{{item.priceType?(item.priceType===1?'':'%'):''}}</td>
              <td>{{item.bottomPrice?item.bottomPrice:'-'}}</td>
              <td>{{item.approvalStatus?item.approvalStatus:'-'}}</td>
              <td class="w-main-color">
                <span @click="toAimDetail(item, '1')">查看</span>
                <span
                  v-if="item.approvalStatus!=='审批中'"
                  @click="toAimDetail(item, '2')"
                >编辑</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          v-if="brandList.total > 20"
          class="w-page-sty"
        >
          <el-pagination
            background
            @current-change="pageChange"
            layout="prev, pager, next"
            :page-size="brandList.pageSize"
            :total="brandList.total"
            :current-page="pageNum"
          >
          </el-pagination>
        </div>
        <div
          class="no-recommend-brand"
          style="padding-top: 80px;"
          v-if="brandList.dataList && brandList.dataList.length === 0"
        >
          <img
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>暂无相关数据</p>
        </div>
      </div>
    </div>
    <div
      class="simpleInfoPanel"
      v-if="!hasPermission"
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      loading: true,
      projectId: '',
      projectName: '',
      projectList: [],
      selectFloor: [],
      bertnNo: '',
      data: {},
      pageNum: 1,
      brandList: {},
      formatStr: '',
      formatList: [],
      approvalStatus: '',
      approvalStatusList: [{
        value: '全部',
        lable: '全部'
      }, {
        value: '审批完成',
        lable: '审批完成'
      }, {
        value: '审批中',
        lable: '审批中'
      }, {
        value: '-',
        lable: '-'
      }],
      filterFloorArr: [],
      building: '',
      floor: '',
      berthStr: '',
      berthNo: '',
      userAuthorities: [],
      hasPermission: true, // 是否有权限，show也是这个
      hasPermissionEdit: true,
      selectProjectObj: {}
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  mounted () {
    this.userAuthorities = this.user.confIds.filter(item => {
      return item.id === 210
    })
    if (this.userAuthorities.length) {
      this.getBerthProjectFunc()
      this.getListIndustry()
      this.hasPermission = Boolean(this.userAuthorities[0].func)
      this.hasPermissionEdit = this.userAuthorities[0].func.indexOf('edit') !== -1
    } else {
      this.hasPermission = false
      this.loading = false
    }
  },
  methods: {
    getBerthProjectFunc () {
      this.axios.post(api.getBerthProjectList)
        .then((res) => {
          this.projectList = res.data.data.map((item) => {
            let obj = {}
            obj = item
            obj.label = item.projectName
            obj.value = item.id
            return obj
          })
          this.getProjectIdFunc().then((res) => {
            this.projectId = res.data.data.projectId
            this.projectName = res.data.data.projectName
            this.getInvestmentList()
            this.getFilterFloorFunc()
          })
        })
    },
    getFilterFloorFunc (id) {
      this.axios.post(api.getWholeFloorData, { projectId: this.projectId })
        .then((res) => {
          this.filterFloorArr = res.data.data.map((item) => {
            item.children = item.children.map((option) => {
              const tem = {
                value: option.value,
                label: option.label
              }
              return tem
            })
            return item
          })
        })
    },
    getInvestmentList () {
      if (JSON.parse(localStorage.getItem('saveScreen'))) {
        this.data = JSON.parse(localStorage.getItem('saveScreen'))
        if (this.data.building) {
          this.selectFloor.push(this.data.building)
          if (this.data.floor) {
            this.selectFloor.push(this.data.floor)
          }
        } else {
          this.selectFloor = []
        }
        this.formatStr = this.data.industry1
        this.approvalStatus = this.data.approvalStatus
        this.berthNo = this.data.berthNo
      }
      this.data.pageSize = 20
      this.data.pageNum = this.pageNum
      this.data.projectId = this.projectId
      this.axios.post(api.getBerthTargetList, this.data)
        .then((res) => {
          this.brandList = res.data.data
          this.loading = false
        })
    },
    getListIndustry () {
      this.axios.post(api.getListIndustry1).then((res) => {
        if (res.data.code === 0) {
          res.data.data.unshift(
            {
              name: '全部',
              id: '',
              value: '全部'
            }
          )
        }
        this.formatList = res.data.data.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
      })
    },
    changeFormat (val) {
      this.formatStr = val
    },
    changeApproval (val) {
      if (val === '全部') {
        this.approvalStatus = ''
      } else {
        this.approvalStatus = val
      }
    },
    changeFloor (val) {
      this.building = ''
      this.floor = ''
      this.building = val[0]
      if (val[1]) {
        this.floor = val[1]
        this.berthStr = this.$refs.myCascader.currentLabels[1]
      }
    },
    changeProject (val) {
      this.selectProjectObj = this.projectList.find((item) => {
        return item.value === val
      })
      this.projectName = this.selectProjectObj.projectName
      this.projectId = val
      this.pageNum = 1
      this.updateProjectIdFunc()
      this.getInvestmentList()
      this.getFilterFloorFunc()
    },
    updateProjectIdFunc () {
      const params = {
        projectId: this.projectId
      }
      this.axios.post(api.updateProjectId, params)
        .then((res) => {
        })
    },
    getProjectIdFunc () {
      return new Promise((resolve, reject) => {
        this.axios.get(api.getProjectId)
          .then((res) => {
            if (res.status === 200) {
              resolve(res)
            } else {
              reject(new Error(''))
            }
          })
      })
    },
    searchBtn () {
      this.loading = true
      this.data.projectId = this.projectId
      if (this.selectFloor[0]) {
        this.data.building = this.selectFloor[0]
        if (this.selectFloor[1]) {
          this.data.floor = this.selectFloor[1]
        } else {
          this.data.floor = ''
        }
      } else {
        this.data.building = ''
        this.data.floor = ''
        this.data.berthId = ''
      }
      this.data.berthNo = this.berthNo
      this.data.industry1 = this.formatStr
      this.data.approvalStatus = this.approvalStatus
      this.data.pageSize = 20
      this.pageNum = 1
      localStorage.setItem('saveScreen', JSON.stringify(this.data))
      this.getInvestmentList()
    },
    pageChange (val) {
      this.loading = true
      this.pageNum = val
      this.getInvestmentList()
    },
    toAimDetail (item, type) {
      this.$router.push({
        path: '/investmentAimManageDetail',
        query: {
          id: item.id,
          berthId: item.berthId,
          targetId: item.targetId,
          projectId: this.projectId,
          type: type,
          berthType: item.berthType,
          building: item.building
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.w-aim-wrap
  margin-top 10px
  margin-left 204px
  width calc(100% - 226px)
  padding-bottom 16px
  .w-aim-head
    display flex
    height 34px
    .w-berth-btn
      margin-left auto
      span
        text-align center
        display inline-block
        line-height 36px
      .w-drop-btn
        width 140px
        border 1px solid #FFA134
        border-radius 3px
        font-size 16px
        color #FFA134
        cursor pointer
        .icon-add:before
          font-size 15px
          color #FFA134
      .w-export-btn
        line-height 34px
        width 110px
        border 1px solid rgba(255, 255, 255, 0.5)
        border-radius 3px
        font-size 16px
        color rgba(255, 255, 255, 1)
        margin-left 20px
        cursor pointer
        .icon-export
          margin-right 10px
  .w-aim-screen
    // height 270px
    background #272930
    box-shadow 0px 6px 12px 0px rgba(0, 0, 0, 0.04)
    border-radius 3px
    margin-top 12px
    padding-left 42px
    padding-top 33px
    padding-bottom 33px
    box-sizing border-box
    .w-screen-list
      max-width 1690px
      display flex
      margin-top 35px
      .w-input-item
        display flex
        align-items center
        margin-right 40px
        .el-input__inner
          font-size 14px
        .w-type-name
          display inline-block
          margin-right 4px
          color #fff
          font-size 14px
          font-weight 400
  .w-singed-table
    background #272930
    box-shadow 0px 6px 12px 0px rgba(0, 0, 0, 0.04)
    border-radius 3px
    margin-top 15px
    box-sizing border-box
    padding 0 24px
    padding-bottom 35px
    table
      width 100%
      thead
        tr
          height 54px
          line-height 54px
          color #999
          font-size 12px
          td
            border-bottom 1px solid rgba(216, 216, 216, 0.1)
            padding-left 10px
      tbody
        tr
          height 47px
          line-height 47px
          color #ffffff
          font-size 12px
          &:hover td
            background-color #23252b
          td
            border none
            border-bottom 1px solid rgba(216, 216, 216, 0.1)
            line-height 24px
            padding-left 10px
            span
              margin-right 10px
              cursor pointer
            .status
              display inline-block
              width 10px
              height 10px
              border-radius 5px
              background #FFA134
              margin-right 5px
</style>
